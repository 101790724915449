import React, { useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import PiauleMap from '../assets/images/PiauleWebsteMap.inline.svg'

const ModuleStyles = styled.div`
  height: 100%;

  .hover-image {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 17%;
    bottom: 22%;

    transition: opacity 250ms;
    width: 40%;
    max-width: 600px;

    @media screen and (max-width: 1023px) {
      position: fixed;
      right: 5%;
      width: 90%;
      max-width: 100%;
    }
  }

  .hover-image.is-portrait {
    bottom: 10%;
    max-width: 425px;
  }

  .hover-image.showing {
    opacity: 1;
  }

  @media screen and (max-width: 1023px) {
    svg {
      height: 90vh;
      place-self: flex-start;
    }
  }

  @media screen and (min-width: 1024px) {
    .text-module {
      min-width: 500px;
    }

    svg {
      max-height: 100%;
    }
  }
`;

export const query = graphql`
  query SectionTemplate($_id: String!) {
    section: sanitySections(_id: { eq: $_id }) {
      title
      landscapeHoverImages {
        asset {
          _id
          originalFilename
          url
          fluid {
            aspectRatio
          }
        }
      }
      modules {
        ... on SanityImageModule {
          _key
          _type
          image {
            alt
            asset {
              url
            }
          }
        }
        ... on SanityStackedImagesModule {
          _key
          _type
          images {
            alt
            asset {
              url
            }
          }
          isOnTop
        }
        ... on SanityTextModule {
          _key
          _type
          text
        }
      }
    }
  }
`

const SectionTemplate = ({ data, pageContext, uri }) => {
  const section = data && data.section
  // const site = useSite();

  const { modules, landscapeHoverImages } = section

  const StackedImagesModule = ({ content }) => {
    const { images, isOnTop } = content;
    const classModifier = isOnTop && images.length === 1 ? ' justify-end' : '';

    return (
      <div className={`stacked-module h-full flex flex-row lg:flex-col lg:flex-shrink-0 lg:justify-start${classModifier} items-start lg:items-end`}>
        <img className="w-1/2 lg:w-auto h-full lg:h-1/2 object-contain" src={images[0].asset.url} alt={images[0].asset.alt} />
        {images[1]
          ? <img className="w-1/2 lg:w-auto h-auto lg:h-1/2 object-contain" src={images[1].asset.url} alt={images[1].asset.alt} />
          : null
        }
      </div>
    )
  }

  const Modules = ({ content, type }) => {
    // switch case statement to map all the modules
    switch (type) {
      case 'imageModule':
        return uri === '/landscape' ? <PiauleMap /> : <img className="image-module object-contain h-auto w-full lg:h-full lg:w-auto" src={content.image.asset.url} alt={content.image.asset.alt} />
      case 'textModule':
        return <p className="text-module serif text-2xl p-16 lg:px-24 lg:py-0 flex justify-center items-center h-full md:w-1/2">{content.text}</p>
      case 'stackedImagesModule':
        return <StackedImagesModule content={content} />
      default:
        return <pre>{JSON.stringify(content)}</pre>
    }
  }

  const HoverImages = () => {
    if (uri !== '/landscape') return '';

    return landscapeHoverImages.map(({ asset }) => {
      const { _id, fluid, originalFilename, url } = asset;
      const filename = originalFilename.replace(/ /g, '_').slice(0, -4);
      const isPortrait = fluid.aspectRatio < 1 ? ' is-portrait' : '';

      return (<img className={`hover-image object-contain ${filename}${isPortrait}`} src={url} alt="landscape hover" key={_id} />)
    });
  }

  const handleMouseOver = ({ currentTarget }) => {
    const targetEl = document.querySelector(`.${currentTarget.id}`);

    if (targetEl) targetEl.classList.add('showing');
  }

  const handleMouseOut = ({ currentTarget }) => {
    const targetEl = document.querySelector(`.${currentTarget.id}`);

    if (targetEl) targetEl.classList.remove('showing');
  }

  useEffect(() => {
    landscapeHoverImages.forEach(({ asset }) => {
      const { originalFilename } = asset;
      const filename = originalFilename.replace(/ /g, '_').slice(0, -4);
      const el = document.querySelector(`#${filename}`);

      if (el) el.addEventListener('mouseover', handleMouseOver);
      if (el) el.addEventListener('mouseout', handleMouseOut);
    })

    return function cleanup() {
      landscapeHoverImages.forEach(({ asset }) => {
        const { originalFilename } = asset;
        const filename = originalFilename.replace(/ /g, '_').slice(0, -4);
        const el = document.querySelector(`#${filename}`);

        if (el) el.removeEventListener('mouseover', handleMouseOver);
        if (el) el.removeEventListener('mouseout', handleMouseOut);
      });
    };
  })

  return (
    <Layout uri={uri}>
      <SEO />
      <ModuleStyles className={`h-full flex flex-col items-center pl-0${uri === '/landscape' ? ' justify-center is-landscape sm:w-full overflow-scroll' : ' lg:flex-row lg:items-start lg:pl-8'}`}>
        {modules.map(module => (
          <Modules type={module._type} content={module} key={module._key} />
        ))}
        <HoverImages />
      </ModuleStyles>
    </Layout>
  )
}

export default SectionTemplate
